import {
  compose,
  setDisplayName,
  branch,
  renderComponent,
  lifecycle
} from 'recompose'
import { graphql } from 'react-apollo'
import { path, isEmpty, propEq } from 'ramda'

import SubscriptionCreditcardView from '../../../components/subscriptions/neon/subscription-creditcard'
import { LoadingModal } from '../../../components/loading/modal'

import SUBSCRIPTION_CREDITCARD_VIEW_QUERY from '../../../../graphql/queries/subscription-creditcard-view.gql'

const enhance = compose(
  setDisplayName('StepOneCreditcardViewContainer'),
  graphql(SUBSCRIPTION_CREDITCARD_VIEW_QUERY, {
    name: 'subscriptionCreditcardViewQuery',
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({
      ownProps: { renderStep },
      subscriptionCreditcardViewQuery: {
        loading: creditcardViewLoading,
        error: creditcardViewError,
        subscriptionCreditcardView
      }
    }) => ({
      onIframeLoad: (iframeQueryParams) => {
        if (isEmpty(iframeQueryParams)) return

        if (propEq('status', 'success', iframeQueryParams)) { // If the Creditcard form submission was successful
          const orderId = path(['orderId'], iframeQueryParams) // Obtain the `orderId` from the iframe return url
          renderStep(2, { orderId })
        }
      },
      creditcardViewLoading,
      creditcardViewError,
      ...(subscriptionCreditcardView || {})
    })
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({ creditcardViewError, iframe, orderId }) {
      // If we're provided with an `orderId` from the subscriptionCreditcardViewQuery,
      // that means the user has been subscribed to the product and there's no
      // payment submission required. This can potentially happen whenever, say, a user
      // on a certain voucher with a default payment setup proceeds to resume their suspended
      // subscription. To put it in layman terms, `orderId` === we're done. goto confirmation view.
      if (orderId) {
        this.props.renderStep(2, { orderId })
        return
      }

      if (creditcardViewError || iframe == null) {
        this.props.renderStep(1)
      }
    }
  }),
  branch(
    ({ creditcardViewLoading, creditcardViewError, iframe }) => (
      creditcardViewLoading || creditcardViewError || iframe == null
    ),
    renderComponent(LoadingModal)
  )
)

export default enhance(SubscriptionCreditcardView)
