import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { sanitizeDom } from '../../utils'

import Imgix from '../imgix'
import styles from './confirmation-view.css'
import checkIcon from '../../../images/forms-check.svg'

import { DeepLink } from '../../hoc/deep-link'
import { STATIC_SCREENS } from '../../constants'

import RENTAL_CONFIRMATION_VIEW_DETAILS_FRAGMENT from '../../../graphql/fragments/rental-confirmation-view-details.gql'
import { E2E_SELECTORS } from '../../e2e-selectors'

const myRentalsPath = STATIC_SCREENS.MY_RENTALS.screenId

function ConfirmationView({
  header,
  subHeader,
  image,
  notes,
  details,
  cta,
  isAuthenticated
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <Imgix src={image} recipe="purchaseConfirmation" className={styles.image} />
      </div>
      <div className={styles.confirmationWrapper}>
        <div className={styles.confirmationHeader}>
          <div className={styles.productInfo}>
            <div className={styles.title}>
              {header}
            </div>
            <div
              className={styles.subheader}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitizeDom(subHeader) }}
            />
            <div className={styles.purchaseInfo}>
              {
                notes.map((note, index) => (
                  <span
                    key={index} // eslint-disable-line react/no-array-index-key
                  >
                    {note}
                  </span>
                ))
              }
            </div>
            <div className={styles.details}>
              {
                details.map(detail => (
                  <div key={detail.name}>
                    <div className={styles.detailLabel}>
                      {detail.name}
                    </div>
                    {
                      detail.value && (
                        <div className={styles.detail}>
                          {
                            detail.icon && (
                              <img
                                className={styles.detailImage}
                                alt={detail.name}
                                src={detail.icon}
                              />
                            )
                          }
                          <div className={styles.detailValue}>
                            {detail.type === 'cc' ? `···· ···· ···· ${detail.value.substr(-4)}` : detail.value}
                          </div>
                          <img alt="valid" className={styles.detailCheck} src={checkIcon} />
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
            <DeepLink
              className={styles.gotoMyRentals}
              to={isAuthenticated ? myRentalsPath : '/?modal=login'}
              data-lbx-e2e={E2E_SELECTORS.GO_TO_MY_RENTALS_BUTTON}
            >
              {cta}
            </DeepLink>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmationView.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  image: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string),
  details: PropTypes.arrayOf(propType(RENTAL_CONFIRMATION_VIEW_DETAILS_FRAGMENT)),
  cta: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired
}

ConfirmationView.defaultProps = {
  header: '',
  subHeader: '',
  notes: [],
  details: [],
  cta: ''
}

export default ConfirmationView
