import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Button from '../../forms/neon/button'
import checkCircleIcon from '../../../../images/check-circle.svg'
import { subscriptionGa } from '../../../lib/analytics/ga'
import { sanitizeDom } from '../../../utils'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-view.gql'

import styles from './neon-voucher-subscription-validated.css'

function NeonVoucherSubscriptionValidated({
  subscription,
  onSubscriptionSelection
}) {
  return (
    <>
      <img alt="Required" src={checkCircleIcon} />

      <h2 className={styles.neonVoucherValidatedHeader}>{subscription.voucherDetail.header}</h2>

      <div className={styles.neonVoucherValidatedCard}>
        <div className={styles.featuresVoucherValidatedHeading}>{subscription.voucherDetail.subHeader}</div>
        <ul>
          {
            // eslint-disable-next-line react/no-array-index-key
            subscription.voucherDetail.desc.map((desc, index) => <li key={index} dangerouslySetInnerHTML={{ __html: sanitizeDom(desc) }} />)
          }
        </ul>
      </div>

      <div className={styles.neonVoucherValidatedSummary}>
        {subscription.voucherDetail.summary}
      </div>

      <Button
        data-e2e="voucher-validated-continue-button"
        text="Continue"
        className={styles.continueButton}
        onClick={() => {
          onSubscriptionSelection(subscription.id)
          subscriptionGa({ action: 'Continue', label: 'neonVoucher' })
        }}
      />
    </>

  )
}

NeonVoucherSubscriptionValidated.propTypes = {
  subscription: propType(SUBSCRIPTION_VIEW_FRAGMENT).isRequired,
  onSubscriptionSelection: PropTypes.func
}

NeonVoucherSubscriptionValidated.defaultProps = {
  onSubscriptionSelection: null
}

export default NeonVoucherSubscriptionValidated
