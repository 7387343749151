import React from 'react'
import PropTypes from 'prop-types'

import EvIframe from '../../../containers/shared/evergent-iframe'

import styles from './subscription-creditcard.css'

function SubscriptionCreditcard({
  header,
  message,
  iframe,
  onIframeLoad
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.header}>
          { header || 'Add payment details' }
        </h1>
        <p className={styles.message}>
          { message || 'Please provide your credit card details below to complete your account set up.' }
        </p>
        <div className={styles.iframeWrapper}>
          <EvIframe
            src={iframe}
            onLoad={onIframeLoad}
          />
        </div>
      </div>
    </div>
  )
}

SubscriptionCreditcard.propTypes = {
  iframe: PropTypes.string.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  header: PropTypes.string,
  message: PropTypes.string
}

SubscriptionCreditcard.defaultProps = {
  header: null,
  message: null
}

export default SubscriptionCreditcard
