import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  compose,
  setPropTypes,
  defaultProps,
  branch,
  withProps,
  renderComponent,
  lifecycle
} from 'recompose'

import { checkQueryStatus } from '../../lib/utils'
import { findDefaultSVODCreditCard } from '../../lib/account'
import ConfirmationView from '../../components/rentals/confirmation-view'
import { LoadingModal } from '../../components/loading/modal'

import RENTAL_CONFIRMATION_VIEW_QUERY from '../../../graphql/queries/rental-confirmation-view.gql'
import ACCOUNT_CREDIT_CARDS_QUERY from '../../../graphql/queries/account-credit-cards.gql'
import CONTENT_ITEM_QUERY from '../../../graphql/queries/content-item.gql'
import MY_RENTALS_QUERY from '../../../graphql/queries/my-rentals.gql'
import { segmentTrackCompletePurchase } from '../../segment/segment-track'

/**
 * Getting the first available card after refetching and confirming
 * that there's currently no default SVOD payment method available
 */
const findFirstAvailableCardId = pathOr('', ['account', 'creditcards', '0', 'id'])

const enhance = compose(
  withRouter,
  withApollo,
  defaultProps({
    orderId: '',
    contentItemId: ''
  }),
  setPropTypes({
    orderId: PropTypes.string,
    contentItemId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }),
  graphql(RENTAL_CONFIRMATION_VIEW_QUERY, {
    name: 'rentalConfirmationQuery',
    options: ({ orderId, contentItemId }) => {
      return {
        variables: {
          orderId,
          contentItemId
        },
        fetchPolicy: 'network-only'
      }
    }
  }),
  graphql(ACCOUNT_CREDIT_CARDS_QUERY, {
    name: 'accountCreditCardsQuery',
    options: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, accountCreditCardsQuery }) => ({
      ...ownProps,
      accountCreditCardsQuery,
      defaultSVODCreditCard: findDefaultSVODCreditCard(accountCreditCardsQuery.account),
      firstAvailableCardId: findFirstAvailableCardId(accountCreditCardsQuery)
    })
  }),
  graphql(CONTENT_ITEM_QUERY, {
    name: 'contentItemQuery',
    skip: ({ contentItemId }) => {
      return !contentItemId
    },
    options: ({ contentItemId }) => {
      return {
        variables: {
          id: contentItemId
        }
      }
    }
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery'
  }),
  branch(
    ({
      rentalConfirmationQuery,
      accountCreditCardsQuery,
      contentItemQuery,
      myRentalsQuery
    }) => {
      return (
        checkQueryStatus(rentalConfirmationQuery) ||
        checkQueryStatus(accountCreditCardsQuery) ||
        checkQueryStatus(contentItemQuery) ||
        checkQueryStatus(myRentalsQuery)
      )
    },
    renderComponent(LoadingModal)
  ),
  lifecycle({
    componentDidMount() {
      // Now that the purchase has been completed, we update the cache so that
      // the total number of rentals is correct without having to refetch
      this.props.myRentalsQuery.updateQuery((previousResult) => {
        const totalCurrentRentals = previousResult.myRentalHistory.totalCurrentRentals + 1
        const items = previousResult.myRentalHistory.items.concat({
          contentItem: {
            __typename: 'Title',
            id: this.props.contentItemId
          },
          expired: false,
          __typename: 'MyRentalItem'
        })

        return {
          ...previousResult,
          myRentalHistory: {
            items,
            totalCurrentRentals,
            __typename: 'MyRentalHistory'
          }
        }
      })
      // Refetch contentItemQuery on route change to get the updated rentalInfo data
      this.props.contentItemQuery.refetch()

      // Add segment data analytics for completing rental purchase
      const contentItem = this.props.contentItemQuery?.contentItem
      if (contentItem) {
        const {
          title, isRental, ldId, products
        } = contentItem
        segmentTrackCompletePurchase({
          title,
          isRental,
          ldId,
          payment: Number(products[0]?.currentPrice)
        })
      }
    }
  }),
  withProps(({ rentalConfirmationQuery }) => ({
    header: pathOr('', ['rentalConfirmationView', 'header'], rentalConfirmationQuery),
    subHeader: pathOr('', ['rentalConfirmationView', 'subHeader'], rentalConfirmationQuery),
    image: pathOr('', ['rentalConfirmationView', 'image', 'uri'], rentalConfirmationQuery),
    notes: pathOr('', ['rentalConfirmationView', 'notes'], rentalConfirmationQuery),
    details: pathOr('', ['rentalConfirmationView', 'details'], rentalConfirmationQuery),
    cta: pathOr('', ['rentalConfirmationView', 'cta'], rentalConfirmationQuery),
    upsellSection: pathOr(null, ['rentalConfirmationView', 'upsell'], rentalConfirmationQuery)
  }))
)

export default enhance(ConfirmationView)
