import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import SubscriptionConfirmationModal
  from './components/subscription-confirmation-modal/subscription-confirmation-modal'
import SubscriptionErrorModal
  from './components/subscription-error-modal/subscription-error-modal'
import { modalContent } from '../shared/subscription-constants'
import SubscriptionVoucherModal
  from './components/subscription-voucher-modal/subscription-voucher-modal'
import SubscriptionPaymentMethodModal
  from './components/subscription-payment-method-modal/subscription-payment-method-modal'
import VoucherValidateErrorAfterLoginModal
  from '../../containers/modals/voucher-validate-error-after-login'
import PlanTableWeb from '../price-table/plan-table-web'
import PlanTableMobile from '../price-table/plan-table-mobile'
import { useSelectPlan } from '../signup/hooks/use-select-plan'

/**
 * Render subscription modal content according to actions
 */
const STANDARD_OLD_TITLE = 'Premium'
const STANDARD_TITLE = 'Standard'

// eslint-disable-next-line react/prop-types
const PickContentComponent = ({ errorMessage, errorOnClick }) => {
  const { search } = useLocation()
  const {
    content, currentSubscription, oneWayChangeDirection, hasRecurringVouchers
  } = qs.parse(search)
  // call useSelectPlan here so the activeSubscription can be shared by PlanTableWeb and PlanTableMobile
  const { activeSubscription, updateActiveSubscription } = useSelectPlan(currentSubscription)

  const getCurrentSubscriptionName = () => {
    // Update title name if api return old title name
    if (currentSubscription === STANDARD_OLD_TITLE) {
      return STANDARD_TITLE
    }

    return currentSubscription
  }

  switch (content) {
    case modalContent.selection:
      return (
        <>
          <PlanTableWeb
            currentSubscription={getCurrentSubscriptionName()}
            oneWayChangeDirection={oneWayChangeDirection}
            hasRecurringVouchers={hasRecurringVouchers}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
          />
          <PlanTableMobile
            currentSubscription={getCurrentSubscriptionName()}
            oneWayChangeDirection={oneWayChangeDirection}
            hasRecurringVouchers={hasRecurringVouchers}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
          />
        </>
      )
    case modalContent.payment:
      return <SubscriptionPaymentMethodModal />
    case modalContent.confirmation:
      return <SubscriptionConfirmationModal />
    case modalContent.voucher:
      return <SubscriptionVoucherModal />
    case modalContent.voucherValidateErrorAfterLogin:
      return <VoucherValidateErrorAfterLoginModal />
    default:
      return <SubscriptionErrorModal errorMessage={errorMessage} onClick={errorOnClick} />
  }
}

const AddSubscriptionContents = () => {
  const history = useHistory()
  const location = useLocation()
  const clickToRefresh = () => {
    history.push(location.pathname)
  }

  return (
    <PickContentComponent
      errorMessage="Please try again"
      errorOnClick={clickToRefresh}
    />
  )
}

export default AddSubscriptionContents
