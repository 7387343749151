import {
  compose, branch, renderComponent, withState, withHandlers, withProps, lifecycle
} from 'recompose'
import { graphql } from 'react-apollo'
import { Formik } from 'formik'
import {
  path,
  pathOr,
  propOr,
  pathEq,
  find,
  propEq,
  trim
} from 'ramda'

import ProfilesAddForm from '../../components/profiles/profiles-add-form'
import withMutateAndLoading from '../../hoc/with-mutate-and-loading'
import { getGQLErrorMsg } from '../../lib/apollo'
import withConfigCacheOnly from '../../hoc/with-config'
import withIsAuthenticatedAndRedirect from '../../hoc/is-authenticated-redirect'
import {
  getAgeGroupOptions,
  getDefaultParentalRating,
  getParentalRatingOptions,
  getKidsParentalRatingOptions
} from '../../lib/parental-ratings'
import { getModalLocation } from '../../lib/modal'
import { LoadingModal } from '../../components/loading/modal'
import getGenderOptions from '../../utils/getGenderOptions'
import { profileFormValidationSchema } from '../../utils/getValidationSchema'
import { MODALS } from '../../constants'
import PROFILE_MUTATION from '../../../graphql/mutations/profile.gql'
import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import { segmentTrackEditProfile } from '../../segment/segment-track'
import getBirthYearOptions from '../../utils/getBirthYearOptions'

const enhance = compose(
  withConfigCacheOnly,
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    }
  }),
  branch(
    ({
      accountQuery
    }) => (
      (
        accountQuery && (
          accountQuery.loading ||
          accountQuery.error
        )
      )
    ),
    renderComponent(LoadingModal)
  ),
  graphql(PROFILE_MUTATION, {
    options: {
      update: (proxy, response) => {
        const data = proxy.readQuery({ query: ACCOUNT_QUERY })
        const getProfiles = pathOr(data.account.profiles, ['data', 'profile', 'profiles'])

        data.account.profiles = getProfiles(response)
        proxy.writeQuery({ query: ACCOUNT_QUERY, data })
      }
    }
  }),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  // Avatar selection
  withProps(({ appConfig, selectedProfile }) => {
    // Avatar aux functions
    const getCurrentAvatar = path(['avatar'])
    const getDefaultAvatar = find(propEq('isDefault', true))
    const getAvatar = propOr(
      getCurrentAvatar(selectedProfile),
      getDefaultAvatar(appConfig.avatars)
    )

    return ({
      avatars: appConfig.avatars,
      avatar: getAvatar(selectedProfile)
    })
  }),
  withState('isAvatarSelected', 'setIsAvatarSelected', false),
  withHandlers({
    onCancel: ({
      history,
      location,
      setIsAvatarSelected,
      isAvatarSelected
    }) => () => {
      if (isAvatarSelected) {
        // Avatar selection is handled using local state and not
        // as a separate location. We set it to false, which takes them
        // back to the edit form.
        setIsAvatarSelected(false)
        return
      }

      if (path(['state', 'from'], location)) {
        history.goBack()
      } else {
        history.push(getModalLocation(
          location,
          MODALS.qsParams.profilesManagement,
          null,
          { from: false }
        ))
      }
    }
  }),
  Formik({
    validateOnChange: true,
    // Define the form's validation schema with Yup.
    validationSchema: profileFormValidationSchema,
    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({
      avatar,
      parentalRatingsData,
      ageGroupsData,
      selectedProfile,
      appConfig
    }) => {
      const ageGroupOptions = getAgeGroupOptions(ageGroupsData)
      const getSelectedAgeGroup = find(pathEq(['value', 'id'], selectedProfile.ageGroup))
      const selectedAgeGroup = getSelectedAgeGroup(ageGroupOptions)
      const maxRating = path(['value', 'maxRating'], selectedAgeGroup)
      const genderOptions = getGenderOptions()
      const configValues = appConfig?.values
      const birthYearOptions = getBirthYearOptions(configValues, false)

      return {
        id: selectedProfile.id,
        isKid: selectedProfile.isKid || false,
        isDefault: selectedProfile.isDefault || false,
        avatar,
        name: selectedProfile.name || '',
        birthYear: birthYearOptions.find(item => item.value === selectedProfile.birthYear) || '',
        gender: genderOptions.find(item => item.value === selectedProfile.gender) || '',
        ageGroup: selectedAgeGroup,
        ageGroups: ageGroupOptions,
        parentalRating: parentalRatingsData.find(rating => (
          rating.rating === selectedProfile.maxRating)) ||
          getDefaultParentalRating(parentalRatingsData),
        parentalRatings: selectedProfile.isKid
          ? getKidsParentalRatingOptions(parentalRatingsData, maxRating)
          : getParentalRatingOptions(parentalRatingsData),
        email: selectedProfile.email || '',
        mobile: (selectedProfile.mobile && trim(selectedProfile.mobile)) || ''
      }
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, {
      props, setStatus, setSubmitting, setFieldValue, setErrors
    }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setStatus('')
      const {
        mutateAndLoading,
        onCancel
      } = props

      mutateAndLoading('profilesEdit', {
        variables: {
          input: {
            id: values.id,
            isKid: values.isKid,
            isDefault: values.isDefault,
            name: trim(values.name),
            email: values.isKid ? '' : values.email,
            avatar: values.avatar.id,
            mobile: values.isKid ? '' : trim(values.mobile),
            ageGroup: values.isKid ? values.ageGroup && values.ageGroup.value.id : undefined,
            maxRating: values.parentalRating.rating || values.parentalRating.value,
            birthYear: values.birthYear?.value,
            gender: values.gender?.value
          }
        }
      })
        .then((res) => {
          // Add segment data analytics for editing profile
          const updatedProfile = res?.data?.profile?.profiles.find(profile => profile?.name === values.name)
          if (updatedProfile) {
            const {
              id, name, gender, birthYear, email, mobile, isKid, maxRating
            } = updatedProfile
            segmentTrackEditProfile({
              id,
              name,
              gender,
              birthYear,
              email,
              mobile,
              isKid,
              maxRating
            })
          }
          setSubmitting(false)
          // update mobile value with the trimmed one
          setFieldValue('mobile', pathOr(null, ['data', 'profile', 'phoneNumbers', 'mobile'], res))
          onCancel()
        })
        .catch((errors) => {
          setSubmitting(false)
          const graphQLErrors = pathOr([], ['graphQLErrors'], errors)
          const nameError = graphQLErrors.find(e => e.code === 'PROFILE_NAME_DUPLICATE_ERROR')
          const emailError = graphQLErrors.find(e => e.code === 'INVALID_INPUT' && e.message.includes('email'))
          const mobileError = graphQLErrors.find(e => e.code === 'INVALID_INPUT' && e.message.includes('mobile'))
          if (nameError) {
            return setErrors({
              name: nameError.message
            })
          }
          if (emailError) {
            return setErrors({
              email: emailError.message
            })
          }
          if (mobileError) {
            return setErrors({
              mobile: mobileError.message
            })
          }

          return setStatus(getGQLErrorMsg(errors))
        })
    }
  }),
  lifecycle({
    // if not kids clear age group
    UNSAFE_componentWillReceiveProps(newProps) {
      const {
        values,
        setValues,
        parentalRatingsData
      } = newProps

      const getAgeGroupIdFromValues = path(['ageGroup', 'value', 'id'])
      const getIsKidsFromValues = path(['isKid'])

      // if values.isKid changes
      if (getIsKidsFromValues(this.props.values) !== getIsKidsFromValues(values)) {
        // Reset age groups and parental ratings according to isKid value
        let { ageGroup } = values
        if (getIsKidsFromValues(values)) {
          ageGroup = values.ageGroups[values.ageGroups.length - 1]
        } else {
          ageGroup = undefined
        }

        const parentalRatings = getKidsParentalRatingOptions(
          parentalRatingsData,
          path(['value', 'maxRating'], ageGroup)
        )
        const parentalRating = parentalRatings[parentalRatings.length - 1]

        setValues({
          ...values,
          parentalRatings,
          parentalRating,
          ageGroup
        })
      }

      // if values.ageGroup changes
      if (getAgeGroupIdFromValues(this.props.values) !== getAgeGroupIdFromValues(values)) {
        // Reset parental ratings according to ageGroup value
        // the current age group value for the profile

        const parentalRatings = getKidsParentalRatingOptions(
          parentalRatingsData,
          path(['value', 'maxRating'], values.ageGroup)
        )
        const parentalRating = parentalRatings[parentalRatings.length - 1]

        setValues({
          ...values,
          parentalRatings,
          parentalRating
        })
      }
    }
  })
)

export default enhance(ProfilesAddForm)
