import React from 'react'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Button from '../../../forms/neon/button'
import { modalContent } from '../../../../modules/shared/subscription-constants'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import { MODALS } from '../../../../constants'
import { segmentTrackClickOnWelcomeBackModal } from '../../../../segment/segment-track'
import styles from './index.css'

/**
 * The welcomeBack modal can display a pop-up for unsubscribed user after login
 * User can either add plan or close the modal
 */

const WelcomeBack = (props) => {
  const { onRequestClose } = props
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const planDetails = usePlanChangeFlag()
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual
  const isShowingDiscount = showBasicDiscount || showStandardDiscount || showAnnualDiscount

  const history = useHistory()
  // Go to price table
  const onClickOk = () => {
    // Add segment data analytics for winback user
    segmentTrackClickOnWelcomeBackModal({
      choosePlan: true,
      backToBrowse: false
    })

    history.replace({
      ...location,
      search: qs.stringify({
        content: modalContent.selection,
        modal: MODALS.qsParams.addSubscription
      })
    })
  }

  const onClickClose = () => {
    // Add segment data analytics for winback user
    segmentTrackClickOnWelcomeBackModal({
      choosePlan: false,
      backToBrowse: true
    })

    onRequestClose()
  }

  const renderHeaderAndCopy = () => {
    const generalMsg = {
      header: 'Welcome back to Neon!',
      msg: 'Click to add your plan now.'
    }

    const headerAndCopy = []
    // when we have promotional price(controlled by FF), we use special msg, otherwise general msg
    if (isShowingDiscount) {
      if (showBasicDiscount) {
        headerAndCopy.push({
          header: planDetails.basic.welcomeBackHeader,
          msg: planDetails.basic.welcomeBackMessage
        })
      }
      if (showStandardDiscount) {
        headerAndCopy.push({
          header: planDetails.standard.welcomeBackHeader,
          msg: planDetails.standard.welcomeBackMessage
        })
      }
      if (showAnnualDiscount) {
        headerAndCopy.push({
          header: planDetails.annual.welcomeBackHeader,
          msg: planDetails.annual.welcomeBackMessage
        })
      }
    } else {
      headerAndCopy.push(generalMsg)
    }

    return (
      headerAndCopy.map((dialog, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <h2 className={styles.header}>
            {dialog.header}
          </h2>
          <p className={styles.copy}>
            {dialog.msg}
          </p>
        </div>
      ))
    )
  }

  return (
    <div className={styles.wrapper}>
      {renderHeaderAndCopy()}
      <div className={styles.buttonRow}>
        <Button
          onClick={onClickOk}
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="Choose Plan"
        />
        <Button
          secondary
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          onClick={onClickClose}
          text="Back to browse"
        />
      </div>
    </div>
  )
}

WelcomeBack.propTypes = {
  onRequestClose: PropTypes.func.isRequired
}

export default WelcomeBack
